import React from "react"
import Header from "../components/header/header"
import "../../styles/about.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

const About = ({ data }) => {
  return (
    <Layout>
      <Header />
      <SEO
        title="About | Meet the Team | Toronto Based Event Planners"
        description="YESInspired has assembled an amazing team of inspired and dedicated people that dedicate their lives to making your event a phenomenal experience. Our team includes seasoned Toronto based, industry experts who have been behind many successful special events, corporate meetings and social occasions. The events team at YESInspired consists of a highly committed and hard-working group of people who leave no stone unturned in their pursuit of providing an intimate, memorable, and unique experience."
      />
      <section className="about-group">
        <div className="about-group-img">
          <span className="about-img-text">Get Inspired</span>
          <Img
            fluid={data.aboutGroupImage.childImageSharp.fluid}
            alt="three woman smiling"
          />
        </div>
        <div className="about-group-text-wrapper">
          <Fade>
            <h1 className="about-group-heading">The YES Collective</h1>
            <p className="about-group-body">
              YESInspired is a professional wedding and event planning service
              based in Toronto, Ontario. The company was previously called S
              Events and Creations, founded by Stephanie Miaco. She invited her
              friends Ysabel Agaton and Edrianne Crisostomo to create what is
              now called YES Inspired. YES Inspired was the brainchild of three
              childhood friends who wanted to build a business that allows them
              to curate events that create new experiences for those attending.
              <br></br>
              <br></br>
              YESInspired is meant to break barriers in the event industry while
              upholding the three main values they treasure: customer
              satisfaction, creativity, and limitless possibilities. Stephanie,
              Ysabel and Edrianne work closely with each client from the very
              beginning, developing an authentic understanding of their personal
              style and tastes. Their diversity allows them to change guest’s
              outlook on events they’ve experienced before.
            </p>
            <p className="about-group-author">Ysabel, Edrianne & Stephanie</p>
          </Fade>
        </div>
      </section>
      <section className="about-bio">
        <div className="about-bio-component bio-1">
          <div className="about-bio-img">
            <Img
              fluid={data.aboutStephImage.childImageSharp.fluid}
              alt="woman sitting on chair"
            />
          </div>
          <div className="about-bio-text-wrapper">
            <Fade>
              <h3 className=" about-bio-sub-heading">
                Event Manager + Designer
              </h3>
              <h2 className=" about-bio-heading">Stephanie</h2>
              <p className="about-bio-body">
                Stephanie is deeply passionate about event planning and event
                design. She will strive to curate your dream celebration,
                whether it is a simple cocktail reception, charity event or
                wedding. Stephanie works closely with each client from the very
                beginning, developing an authentic understanding of their
                personal style and tastes and focuses on the finer details of
                each design aspect, securing your trust and confidence
                throughout the entire coordination process.
                <br></br>
                <br></br>
                Stephanie graduated from Durham College with a diploma in Music
                Business Management & Special Events Planning. She is also
                affiliated with Wedding Planners Institute of Canada (WPIC) and
                the Ontario Wedding Association and is a certified Wedding
                Planner & Designer.Through dedication, knowledge and creativity.
                As a Toronto Event Planner, Stephanie can bring your vision to
                paper, then to reality on your event day. She will be able to
                guide you through the event planning process starting from it's
                first stage all the way to the end of the event.
              </p>
            </Fade>
          </div>
        </div>
        <div className="about-bio-component about-bio-component-2">
          <div className="about-bio-img about-bio-img-2">
            <Img
              fluid={data.aboutYsabelImage.childImageSharp.fluid}
              alt="woman sitting on chair"
            />
          </div>
          <div className=" about-bio-text-wrapper-2">
            <Fade>
              <h3 className=" about-bio-sub-heading">
                Event Manager + Logistics
              </h3>
              <h2 className=" about-bio-heading">Ysabel</h2>
              <p className="about-bio-body">
                Ysabel is a recent graduate of the Humber College Hospitality
                Event Management Program. She has previous experience leading
                and running events through various volunteer opportunities
                before, during, and after her schooling.
                <br></br>
                <br></br>
                She brings years of customer service experience to every event
                and has curated her own system to ensure client satisfaction
                through every interaction. She is passionate about curating
                events for clients to guarantee that their dream events become a
                reality.
              </p>
            </Fade>
          </div>
        </div>
        <div className="about-bio-component bio-3">
          <div className="about-bio-img">
            <Img
              fluid={data.aboutEdrianneImage.childImageSharp.fluid}
              alt="woman sitting on chair"
            />
          </div>
          <div className="about-bio-text-wrapper">
            <Fade>
              <h3 className=" about-bio-sub-heading">Marketing</h3>
              <h2 className=" about-bio-heading">Edrianne</h2>
              <p className="about-bio-body">
                Edrianne has an eye for design and is genuinely passionate in
                coordinating events. She is driven by the process of event
                planning. Edrianne is often coordinating, crafting or decorating
                for some upcoming festivity! She spent years being creative and
                crafty, and watched as her hobby turned into her passion. Her
                years of experience working alongside Stephanie made her fall in
                love with event planning and design.
                <br></br>
                <br></br>
                Working with Edrianne will allow you to brand and personalize
                your event, from stationery to curating personalized gift boxes.
                She’ll help you execute your ideas in an aesthetic and cohesive
                way that people will be admiring. Her skills in leadership,
                problem solving skills and her adaptability from working in the
                healthcare industry will definitely shine through when you work
                with her!
              </p>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default About

export const aboutQuerry = graphql`
  query {
    aboutGroupImage: file(relativePath: { eq: "about-group.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutStephImage: file(relativePath: { eq: "about-steph.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutYsabelImage: file(relativePath: { eq: "about-ysabel.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutEdrianneImage: file(relativePath: { eq: "about-edrianne.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
